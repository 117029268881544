<template>
  <div id="page-user-view">
    <div class="grid md:grid-cols-2 gap-6">
      <shipblu-card :cardLoading="cardLoading" class="col-span-1 p-5">
        <p class="text-blue-900 font-medium text-xl leading-tight">{{$t('Customer Information')}}</p>
        <div class="mt-5">
          <div class="leading-tight">
            <span class="text-darkgray text-base">{{$t('Customer Name')}}:</span>
            <span class="text-primary text-base font-medium"> {{orderData.delivery_order.customer.full_name}}</span>
          </div>
          <div class="leading-tight mt-3">
            <span class="text-darkgray text-base">{{$t('Customer Phone')}}:</span>
            <span class="text-primary text-base font-medium"> {{orderData.delivery_order.customer.phone}}</span>
          </div>
          <div class="leading-tight mt-3">
            <span class="text-darkgray text-base">{{$t('Customer Email')}}:</span>
            <span class="text-primary text-base font-medium"> {{orderData.delivery_order.customer.email ? orderData.delivery_order.customer.email : '--'}}</span>
          </div>
          <div class="leading-tight mt-3">
            <span class="text-darkgray text-base">{{$t('Customer Address')}}:</span>
            <span class="text-primary text-base font-medium"> {{orderData.delivery_order.customer.address.line_1}}, {{orderData.delivery_order.customer.address.line_2}}</span>
          </div>
          <div class="leading-tight mt-3">
            <span class="text-darkgray text-base">{{$t('Customer Governorate & City')}}:</span>
            <span class="text-primary text-base font-medium"> {{$t(orderData.delivery_order.customer.address.zone.city.governorate.name)}} / {{$t(orderData.delivery_order.customer.address.zone.city.name)}}</span>
          </div>
          <div class="leading-tight mt-3">
            <span class="text-darkgray text-base">{{$t('Customer Zone')}}:</span>
            <span class="text-primary text-base font-medium"> {{$t(orderData.delivery_order.customer.address.zone.name)}}</span>
          </div>
        </div>
      </shipblu-card>
      <shipblu-card :cardLoading="cardLoading" class="col-span-1 p-5">
        <p class="text-blue-900 font-medium text-xl leading-tight">{{$t('Price')}}</p>
        <div class="mt-5">
          <div class="leading-tight flex justify-between mb-3" v-for="price in orderData.order_price.price_breakdown_item" :key="price.index">
            <span class="text-darkgray text-base">{{price ? price.description : ''}}</span>
            <span class="text-blue-900 text-base font-medium">{{price ? price.value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}}</span>
          </div>
          <div class="leading-tight flex justify-between mt-3 mb-3" v-for="discount in orderData.order_price.discount_breakdown_item" :key="discount.index">
            <span class="text-darkgray text-base">{{discount ? discount.description : ''}}</span>
            <span class="text-blue-900 text-base font-medium">{{discount ? - discount.value : ''}}</span>
          </div>
          <div class="leading-tight flex justify-between mt-3">
            <span class="text-darkgray text-base">{{$t('Subtotal')}}</span>
            <span class="text-blue-900 text-base font-medium">{{orderData.order_price === null ? orderData.delivery_order.order_price.subtotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : orderData.order_price.subtotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span>
          </div>
          <div class="leading-tight flex justify-between mt-3">
            <span class="text-darkgray text-base">{{$t('VAT (14%)')}}</span>
            <span class="text-blue-900 text-base font-medium">{{orderData.order_price === null ? orderData.delivery_order.order_price.vat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : orderData.order_price.vat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span>
          </div>
          <vs-divider class="w-full"></vs-divider>
          <div class="leading-tight flex justify-between mt-5">
            <span class="text-blue-900 text-base font-medium">{{$t('Total Price')}}</span>
            <span class="text-primary text-base font-semibold">{{orderData.order_price === null ? orderData.delivery_order.order_price.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : orderData.order_price.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole !== 'sales' && ((orderData.delivery_order.status === 'delivered' ||orderData.delivery_order.status === 'returned') && orderData.return_order.status === 'returned')" class="mt-5" @click="getInvoice(orderData)">
          <vs-button id="invoice-btn-loading" class="vs-con-loading__container w-full">{{$t('Download Invoice')}}</vs-button>
        </div>
      </shipblu-card>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['orderData', 'cardLoading'],
  data () {
    return {
      editable: false,
      governorates: [],
      cities: [],
      zones: [],
      governorate: {},
      city: {},
      zone: {}
    }
  },
  methods: {
    getInvoice (data) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#invoice-btn-loading',
        scale: 0.45
      })
      sendRequest(true, false, this, `api/v1/billing/merchants/${this.orderData.delivery_order.merchant_id}/invoices/?invoiceexchange__exchange=${data.id}`, 'get', null, true, 
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
            this.$vs.loading.close('#invoice-btn-loading > .con-vs-loading')
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(true, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
          this.$vs.loading.close('#invoice-btn-loading > .con-vs-loading')
        }
      )
    }
  },
  components: {
    ShipbluCard
  }
}
</script>