<template>
  <div id="page-user-view">
    <shipblu-card class="h-full p-5">
      <div class="grid md:grid-cols-2 relative">
        <router-link  :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: {type: 'delivery-orders', orderID: orderData.delivery_order.id}}">
          <div class="col-span-1 md:pr-12 md:mb-0 mb-6">
            <shipblu-card :cardLoading="cardLoading || outboundLoading" class="cursor-pointer hover:shadow-drop h-full p-5">
              <p class="text-blue-900 font-medium text-xl leading-tight">{{$t('Delivery Order')}}</p>
              <div class="mt-5">
                <div class="leading-tight">
                  <span class="text-darkgray text-base">{{$t('Tracking Number')}}:</span>
                  <span class="text-primary text-base font-medium"> {{orderData.delivery_order.tracking_number}}</span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('Picked up On')}}:</span>
                  <span class="text-primary text-base font-medium"> 
                    {{orderData.delivery_order.pickup_date ? 
                    common.gettingDate(new Date(orderData.delivery_order.pickup_date).toISOString()) : ''}}
                  </span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('Service Level')}}:</span>
                  <span class="text-primary text-base font-medium"> {{orderData.delivery_order.service_level ? orderData.delivery_order.service_level.name : ''}}</span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('CoD')}}:</span>
                  <span class="text-primary text-base font-medium"> {{ parseInt(orderData.delivery_order.cash_amount) > 0 ? (orderData.delivery_order.cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}</span>
                </div>
                <div class="leading-tight mt-1 flex items-center">
                  <p class="text-darkgray text-base">{{$t('Packages')}}:</p>
                  <div class="clearfix">
                    <div v-for="(item, index) in orderData.delivery_order.packages" :key="index" class="flex float-left mx-2 my-1">
                      <div class="flex items-center py-1 px-3 package-border">
                        <img class="w-5 h-6" src="@/assets/images/pages/Mask-group.svg" alt=""/>
                        <span class="ml-1 text-primary text-lg font-medium">{{ item.package.package_size.short_code.split('-')[0] }}</span>
                      </div>
                      <div v-if="item.package.fragile" class="package-border py-2 px-3 ml-2">
                        <img src="@/assets/images/pages/active-fragile.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="orderData.delivery_order.fulfillment_provider_metadata">
                <p class="text-blue-900 font-medium text-xl leading-tight mt-6">{{$t('Outbound Products')}}</p>
                <div class="exchange-table mt-4">
                  <table>
                    <thead>
                      <tr>
                        <td>{{$t('Fulfillment Center')}}</td>
                        <td>{{$t('Product SKU')}}</td>
                        <td>{{$t('Outcoming Quantity')}}</td>
                        <td>{{$t('Flags')}}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in outboundOrder.products ? outboundOrder.products : []" :key="index">
                        <td>{{ item.fulfillment_center.name }}</td>
                        <td>{{ item.product.sku }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                          <p class="mb-1" v-for="(flag, index) in item.flags" :key="index">{{ flag.name }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </shipblu-card>
          </div>
        </router-link>
        <div class="absolute divide md:block hidden">
          <img class="h-full w-full" src="@/assets/images/pages/exchange-divide.svg" alt="">
        </div>
        <router-link  :to="{ name: `${$store.state.AppActiveUser.userRole}-returns`, params: {type: 'returns', orderID: orderData.return_order.id}}">
          <div class="col-span-1 md:pl-12">
            <shipblu-card :cardLoading="cardLoading || inboundLoading" class="cursor-pointer hover:shadow-drop h-full p-5">
              <p class="text-blue-900 font-medium text-xl leading-tight">{{$t('Return Order')}}</p>
              <div class="mt-5">
                <div class="leading-tight">
                  <span class="text-darkgray text-base">{{$t('Tracking Number')}}:</span>
                  <span class="text-primary text-base font-medium"> {{orderData.return_order.tracking_number}}</span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('Picked up On')}}:</span>
                  <span class="text-primary text-base font-medium"> 
                    {{orderData.return_order.pickup_date ? 
                    common.gettingDate(new Date(orderData.return_order.pickup_date).toISOString()) : ''}}
                  </span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('Service Level')}}:</span>
                  <span class="text-primary text-base font-medium"> {{orderData.return_order.service_level ? orderData.return_order.service_level.name : ''}}</span>
                </div>
                <div class="leading-tight mt-3">
                  <span class="text-darkgray text-base">{{$t('CoD')}}:</span>
                  <span class="text-primary text-base font-medium"> {{ parseInt(orderData.return_order.cash_amount) > 0 ? (orderData.return_order.cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}</span>
                </div>
                <div class="leading-tight mt-1 flex items-center">
                  <p class="text-darkgray text-base">{{$t('Packages')}}:</p>
                  <div class="clearfix">
                    <div v-for="(item, index) in orderData.return_order.packages" :key="index" class="flex float-left mx-2 my-1">
                      <div class="flex items-center py-1 px-3 package-border">
                        <img class="w-5 h-6" src="@/assets/images/pages/Mask-group.svg" alt=""/>
                        <span class="ml-1 text-primary text-lg font-medium">{{ item.package.package_size.short_code.split('-')[0] }}</span>
                      </div>
                      <div v-if="item.package.fragile" class="package-border py-2 px-3 ml-2">
                        <img src="@/assets/images/pages/active-fragile.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="orderData.delivery_order.fulfillment_provider_metadata">
                <p class="text-blue-900 font-medium text-xl leading-tight mt-6">{{$t('Inbound Products')}}</p>
                <div class="exchange-table mt-4">
                  <table>
                    <thead>
                      <tr>
                        <td>{{$t('Fulfillment Center')}}</td>
                        <td>{{$t('Product SKU')}}</td>
                        <td>{{$t('Incoming Quantity')}}</td>
                        <td>{{$t('Flags')}}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in inboundOrder.products ? inboundOrder.products : []" :key="index">
                        <td>{{ item.fulfillment_center.name }}</td>
                        <td>{{ item.product.sku }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                          <span class="mb-1" v-for="(flag, index) in item.flags" :key="index">{{  index != item.flags.length-1 ? flag.name + ', ' : flag.name }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </shipblu-card>
          </div>
        </router-link>
      </div>
    </shipblu-card>
    <div class="mt-6">
      <p class="text-blue-900 text-lg font-medium leading-tight pl-4">{{ $t('Order Tracking')}}</p>
      <shipblu-card :cardLoading="cardLoading" class="mt-4 p-5">
        <div class="flex">
          <div v-for="(item, index) in status" :key="index" class="text-center w-full">
            <div class="flex items-center relative">
              <p class="rounded-full w-6 h-6 m-auto z-10" 
              :class="status.findIndex((item) => {return item.value === common.getExchangeStatusLabel(orderData)}) > index ?
              'bg-blue-900' : common.getExchangeStatusLabel(orderData) === item.value ? 
              'bg-border-blue' : 'bg-darkgray'"></p>
              <p v-if="index !== 3" :class="status.findIndex((item) => {return item.value === common.getExchangeStatusLabel(orderData)}) > index ? 
                'active-border' : 'inactive-border'"></p>
              <div v-if="index === 2" class="arrows flex z-10 absolute">
                <span class="material-icons text-green font-bold text-xl">keyboard_double_arrow_right</span>
                <span class="material-icons text-orange font-bold text-xl">keyboard_double_arrow_right</span>
              </div>
            </div>
            <p class="mt-3"
            :class="status.findIndex((item) => {return item.value === common.getExchangeStatusLabel(orderData)}) > index ?
              'text-blue-900 font-medium' : common.getExchangeStatusLabel(orderData) === item.value ? 
              'text-primary font-medium' : 'text-darkgray'">{{ item.title }}</p>
            <p class="text-darkgray leading-none text-sm mt-1">{{ item.date ? common.gettingDate(item.date).split(', ')[1] : '' }}</p>
          </div>
        </div>
      </shipblu-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['orderData', 'outboundOrder', 'inboundOrder', 'cardLoading', 'outboundLoading', 'inboundLoading'],
  data () {
    return {
      common,
      serviceLevels: [],
      editable: false,
      status: [
        {
          title: 'Created',
          value: 'created',
          date: this.orderData.created
        },
        {
          title: 'Picked_up to Exchange',
          value: 'picked up to exchange',
          date: this.orderData.delivery_order.pickup_date
        },
        {
          title: 'Swapping',
          value: 'Swapping',
          date: this.orderData.delivery_order.delivered_date
        },
        {
          title: 'Exchanged',
          value: 'Exchanged',
          date: this.orderData.return_order.returned_date
        }
      ],
      outboundOrderSelected: [],
      inboundOrderSelected: []
    }
  },
  watch: {
    orderData () {
      this.status.forEach((item, index) => {
        switch (index) {
        case 0:
          item.date = this.orderData.created
          break
        case 1:
          item.date = this.orderData.delivery_order.pickup_date
          break
        case 2:
          item.date = this.orderData.delivery_order.delivered_date
          break
        case 3:
          item.date = this.orderData.return_order.returned_date
          break
        }
      })
    }
  },
  methods: {
    goToOrderView (type, orderID) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-${type}`,
        params: {
          type,
          orderID
        }
      }).catch(() => {})
      this.$route.query.type = type
    }
  },
  components: {
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard
  }
}
</script>

<style lang="scss" scoped>
.package-border {
  border: 0.6px solid #1C5BFE;
  border-radius: 4px;
}
.divide {
  left: calc(50% - 40px);
  width: 80px;
  height: 100%;
}
.bg-darkgray {
  background: #BCC1C7;
}
.bg-border-blue {
  background: #1C5BFE;
  border: 2px solid #A0D0FD;
}
.active-border {
  border-bottom: 1px solid #1C5BFE;
  position: absolute;
  left: 50%;
  width: 100%;
}
.inactive-border {
  border-bottom: 0.6px solid #9DADC2;
  position: absolute;
  left: 50%;
  width: 100%;
}
.arrows {
  top: calc(50% - 8.25px);
  left: -17.5px;
  .text-green {
    color: #17814C;
  }
  .text-orange {
    color:#E2A171;
  }
}
[dir=rtl] .arrows {
  transform: rotate(180deg);
}
div.exchange-table {
  table {
    width: 100%;
    min-width: 500px;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
    td {
      text-align: center;
      padding: 10px 0px 5px;
      font-size: 14px;
      color: #1c5bfe;
    }
    thead {
      border: 1px solid #0a3266;
      border-left: none;
      border-right: none;
      td {
        color: #6C84A3;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 14px;
        padding: 14px 0;
      }
    }
  }
}
</style>
